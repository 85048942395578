import React, { useEffect, useState } from "react";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
function Modal({ isOpen, onClose, children }: ModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <div className="modal-overlayTERMS" onClick={onClose}>
      <div className="modal-contentTERMS" onClick={(e) => e.stopPropagation()}>
        <button className="modal-closeTERMS" onClick={onClose}>
          ×
        </button>
        {children}
      </div>
    </div>
  );
}
const Terms = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="terms">
      <a className="termsLink"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
      >
        Terms & Conditions
      </a>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>GENERAL TERMS AND CONDITIONS OF archiCODE</h2>
        <h2>A. GENERAL PART</h2>
        <p><strong>1. Definitions</strong></p>
        <p>1.1 In these general terms and conditions the following terms have the stated meaning:</p>
        <p>a. General Terms and Conditions: these general terms and conditions.</p>
        <p>b. Contracted Party: archiCODE (Chamber of Commerce number: ), with its registered office and principal place of
        business in Den Bosch as well as its legal successors by universal or particular title, and the
        natural persons or legal entities affiliated or to be affiliated with it.</p>
        <p>c. Client: any natural person or legal entity who commissions the Contracted Party to deliver goods
        and/or perform work, which is accepted by the Contracted Party.</p>
        <p>d. Parties: the Contracted Party and the Client jointly.</p>
        <p>e. Agreement: the agreement between the Client and the Contracted Party.</p>
        <p>f. Services: services to be provided and/or work to be performed by or on behalf of the Contracted
        Party under the Agreement, including the design and development of software (web & mobile
        apps) (‘Software’).</p>
        <p>g. Goods: the goods to be supplied by or on behalf of the Contracted Party under the Agreement.</p>
        <p>1.2 If, in addition to the general part (A), (several) parts of the special part (B) apply to the
        Agreement, the provisions of the special part prevail in the event of any conflict.</p>
        <p><strong>2. Applicability</strong></p>
        <p>2.1 The General Terms and Conditions apply to all Agreements, including the phase prior to the
formation of the Agreements (requests, offers and quotations). The applicability of the general purchase
conditions or general terms and conditions of the Client is explicitly rejected by the Contracted Party.</p>
<p>
2.2 After they have become part of any Agreement, these General Terms and Conditions will also
form part of any Agreements concluded thereafter, even if no reference was made to the General Terms
and Conditions upon the formation of those Agreements or if they were not submitted.
</p>
<p>
2.3 The Contracted Party is authorised to make changes to these General Terms and Conditions
unilaterally, which changes take effect 30 calendar days after the date on which the Contracted Party
sent the changed terms and conditions to the Client.
</p>
<p>
2.4 If one or more provisions of the Agreement or General Terms and Conditions are invalid, in
violation of the law or otherwise unenforceable, such does not affect the validity of the other provisions.
In that case, the Parties will negotiate in consultation on a new provision to replace that provision, the
starting point being that this new provision approximates the purport of the provision to be replaced as
much as possible.</p>
<p><strong>3. Offers and formation/content of the Agreement</strong></p>
<p>3.1 All offers of the Contracted Party, including in terms of prices, work and performance, are
completely without obligation, indicative and non-binding.
</p>
<p>
3.2 An Agreement is formed at the time that a quotation to that effect from the Contracted Party
has been accepted either orally or in writing by the Client, or if the Contracted Party has started with the
performance of the Agreement. In the latter case, the Contracted Party’s invoice also applies as an order
confirmation.
</p><p>
3.3 Additions and/or changes to Agreements, including the cancellation of Agreements, only apply if
and in so far as they have been laid down in writing by both Parties.
</p><p>
3.4 The Contracted Party is authorised to engage third parties in the performance of the
Agreement, the costs of which will be charged on to the Client in accordance with the submitted
quotation.
</p><p>
3.5 The Parties rule out the applicability of Sections 6:227b (1) and 6:227c of the Dutch Civil Code.</p>
<p><strong>4. Prices</strong></p>
<p>4.1 Unless otherwise agreed in the Agreement, the prices quoted by the Contracted Party are
exclusive of VAT and other taxes, levies or duties.
</p><p>
4.2 The charged prices are based on the known price-determining factors at the time of the
Agreement’s formation. The Contracted Party is authorised to charge on to the Client any changes in
cost price factors concerning the Agreement, such as prices of raw materials, materials, resources,
labour costs, energy costs, transport costs, insurance premiums, exchange rates, taxes, levies or other
government measures.
</p><p>
4.3 After notification as referred to in paragraph 2 of this article, the Client has the right to
terminate the Agreement if the Contracted Party adjusts the stipulated price within 3 (three) calendar
weeks after conclusion of the Agreement. Termination by the Client must take place in writing within 1
(one) calendar week after notification of an adjustment of the price, in the absence of which the Parties
are deemed to have reached agreement on the price increase announced by the Contracted Party.</p>
<p><strong>5. Payments</strong></p>
<p>5.1 Invoices must be paid within the term of payment stated on the invoice. If no term of payment
is stated, payment must be made within 30 (thirty) calendar days at the latest after the invoice date. If
the Parties have agreed on advance payment, this advance payment must take place no later than 7
(seven) calendar days after the formation of the Agreement. All payment terms must be regarded as
strict deadlines within the meaning of Section 6:38 (a) of the Dutch Civil Code.
</p><p>
5.2 In the absence of payment in good time, the Client is in default by operation of law from the
expiry of the payment term, without any further notice of default being required, and from the date of
default until the date of payment in full the Client owes interest on the outstanding amount (including
VAT) within the meaning of Section 6:119a and Section 6:120 of the Dutch Civil Code.
</p><p>
5.3 If the Client is in default with respect to the fulfilment of one or more of their obligations, all
judicial and extrajudicial costs are payable by the Client, subject to a minimum of 15% of the principal
sum.</p>
<p><strong>6. Services</strong></p>
<p>6.1 In performing the Services, the Contracted Party will observe due care, in a manner befitting a
good contracted party. Unless the result is sufficiently determinable and has been agreed explicitly in the
Agreement, the Services are regarded as obligations to use best endeavours.
</p><p>
6.2 The Contracted Party is entitled to replace the person(s) deployed for the Services with another
person or persons with the same or similar qualifications.
</p><p>
6.3 If the Contracted Party performs the Services on the basis of information to be submitted by the
Client, this information will be prepared by the Client in accordance with</p>
<p><strong>7. Delivery time</strong></p>
<p>7.1 Periods that apply to the Contracted Party are approximate and not strict deadlines.</p>
<p>
7.2 An agreed period takes effect after the Agreement has been formed, and once all information
necessary for the performance of the Agreement is in the Contracted Party’s possession.
</p><p>
7.3 The Contracted Party is not liable for exceeding the period for any reason whatsoever. The
exceeding of a period does not oblige the Contracted Party to pay any compensation and does not entitle
the Client to terminate the Agreement and/or to refuse to take delivery and/or to rely on suspension.</p>
<p><strong>8. Complaints and lodging complaints</strong></p>
<p>8.1 The Client is obliged to inspect the Software and/or Services immediately upon their delivery.
Visible defects – also including deviations from the Agreement – must be reported to the Contracted Party
in writing within 1 (one) month after delivery, and the report must contain a description of the
shortcoming that is as detailed as possible, in the absence of which the Client cannot rely on this defect.
</p><p>
8.2 Other defects must be reported to the Contracted Party in writing within 1 (one) month after
they have been established or could reasonably have been established, and the report must contain a
description of the shortcoming that is as detailed as possible, in the absence of which the Client cannot
rely on this defect.
</p><p>
8.3 Complaints about the Contracted Party’s invoices must be expressed to the Contracted Party in
writing within 5 (five) working days after the invoice date, in the absence of which the invoice is
considered to have been approved by the Client.
</p><p>
8.4 Complaints can never affect any of the Contracted Party’s claim(s) against the Client, nor lead to
any claim of the Client against the Contracted Party, if there is force majeure on the part of the
Contracted Party.
</p><p>
8.5 Complaints do not suspend the payment obligation of the Client. Payment by the Contracted
Party as a result of a complaint by the Client shall be made with deduction of the Contracted Party’s due
and payable in full claims against the Client.
</p><p>
8.6 The Client’s claims shall lapse 2 (two) years after he has informed the Contracted Party of the
complaint in accordance with this article.</p>
<p><strong>9. Risk and security</strong></p>
<p>9.1 All Goods remain the Contracted Party’s property until the time of payment in full of all that the
Client owes the Contracted Party. The ownership does not pass to the Client until after the Contracted
Party has confirmed this in writing, if necessary at the Client’s request.
</p><p>
9.2 During the period that the ownership of the Goods still rests with the Contracted Party, the
Client is obliged to retain the Goods in such a manner that it is completely clear at all times that the
Goods belong to the Contracted Party.
</p><p>
9.3 In the cases in which the Agreement ends (early), including the termination, nullification or
invalidity of the Agreement, the Contracted Party has the right to cancel any Goods still to be delivered,
or to suspend delivery, and to claim back the ownership of any Goods already delivered if the Client still
has any payment obligation or other obligation towards the Contracted Party, such without prejudice to
the Contracted Party’s right to claim additional compensation of loss.
</p><p>
9.4 In case of (early) termination of the Agreement, any claim of the Contracted Party against the
Client is immediately due and payable, without any notice of default.
</p><p>
9.5 By means of the formation of the Agreement, the Client grants the Contracted Party irrevocable
power of attorney to immediately take back, or have a third party take back, any Goods not (yet) paid
and to do anything that is relevant in that regard, regardless of where the Goods are located.
</p><p>
9.6 The Client is not allowed to make any changes to the Goods whose ownership rests with the
Contracted Party. Neither is the Client allowed to transfer these Goods and/or rights under the
Agreement and/or to encumber them with any restrictive right or other right. In this context, the Parties
intend the effect under property law of Section 3:83 (2) of the Dutch Civil Code.
</p><p>
9.7 The Client is obliged to create security or additional security on the Contracted Party’s demand
for the fulfilment of their obligations towards the Contracted Party. The Client grants the Contracted
Party irrevocable power of attorney to create this security. If no or insufficient security is created
through the actions of the Client, the Contracted Party is authorised to terminate the Agreement. In that
case, the Client is liable for all of the Contracted Party’s loss.</p>
<p><strong>10. Duration, suspension, set-off, termination and early termination</strong></p>
<p>10.1 The Agreement is entered into for an indefinite period of time, unless its content, nature or
purport implies that it is entered into for a definite period of time.</p>
<p>
10.2 If an Agreement (for a definite or indefinite period of time) has been concluded between the
Parties, such Agreement shall commence on the first day of the Service or performance to be provided
unless another commencement date has been agreed in writing.
</p><p>
10.3 The Agreement entered into for a definite period is automatically extended by the same period
if the Agreement is not terminated in writing by one of the Parties at least one month before the end of
the initial term or of the extension period. In the event that an Agreement is tacitly renewed for a
definite period, this will be under the same conditions as agreed in the original Agreement.
</p><p>
10.4 In the event that the Contracted Party terminates the Agreement, the Contracted Party will be
entitled to immediate payment by the Client of compensation consisting of the obligations that have
lapsed but have not yet been paid and the obligations that the Client would have been obliged to pay if
the Agreement had been maintained, plus interest and costs. With regard to the amount of the
aforementioned compensation, the administration of the Contracted Party will constitute compelling
evidence, subject to evidence to the contrary to be provided by the Client.</p>
<p>10.5 The Contracted Party is authorized to suspend performance of its obligations or terminate the
Agreement if:
a) The Client does not fulfil the obligations under the Agreement even after being requested to do
so (in writing or orally), partially, in full or in a timely manner;
b) After the conclusion of the Agreement, the Contracted Party has become aware of
circumstances that give good reason to believe that the Client will not fulfil the agreed
obligations;
c) The Client has been granted suspension of payments, declared bankruptcy, been declared
subject to a (statutory) debt arrangement, been admitted to the WSNP or has been granted a
winding-up order;
d) Due to delay on the part of the Client, the Contracted Party can no longer be required to
perform the Agreement at the originally agreed conditions
e) Circumstances arise which, in the opinion of the Contracted Party, are of such a nature that
fulfilment of the Agreement is impossible or unaltered maintenance of the Agreement cannot
reasonably be required of the Contracted Party.</p>
<p>10.6 If suspension or termination of the Agreement is attributable to the Client, the Contracted Party
is entitled to compensation for damages, including the costs incurred (in)directly as a result.</p>
<p>
10.7 Due to a termination of the Agreement, the Contracted Party’s claims against the Client shall be
immediately due and payable in full.
</p><p>
10.8 The Client hereby waives the right of early termination. If the Agreement is a continuing
performance contract, it may be terminated by Contracted Party at any time subject to one (1) months’
notice, whereby notice of termination shall be given at the end of a calendar month.
</p>
<p>
10.9 To the extent that an Agreement concerns an assignment agreement, the applicability of
Sections 7:404, 7:407 (2) and 7:409 of the Dutch Civil Code is excluded.
</p><p>
10.10 The Client hereby waives the right of set-off, dissolution or suspension of obligations under the
Agreement. By doing so, the Client also waives the possibility of invoking Section 6:271 of the Dutch
Civil Code.</p>
<p><strong>11. Force majeure</strong></p>
<p>11.1 In addition to the provisions of Section 6:75 of the Dutch Civil Code, force majeure is
understood to mean circumstances with respect to persons and/or equipment that the Contracted Party
uses or tends to use in the performance of the Agreement, which circumstances are of such a nature
that the performance of the Agreement becomes impossible or inconvenient and/or disproportionately
expensive to such extent that fulfilment of the Agreement can no longer reasonably be required of the
Contracted Party.</p>
<p>11.2 Force majeure in any event includes, but is not limited to: government measures;
work strikes; exclusions; obstructions by third parties; transport difficulties, including delays at national
borders; technical complications unforeseen by the Parties; stagnation caused by cold weather-related
downtime and other weather influences; fire, explosion and other major failures in the Contracted
Party’s company that are not at the Contracted Party’s risk, and the circumstance that the Contracted
Party is not, not in good time or not properly delivered a third-party performance that is relevant in
connection with a performance to be delivered by the Contracted Party.</p>
<p>11.3 The obligations of the Contracted Party are suspended during a situation of force majeure. If
the period in which fulfilment of the obligations by the Contracted Party is not possible due to force
majeure lasts longer than 1 (one) month, the Contracted Party is authorised to terminate the
Agreement, without in such case there being any obligation to compensate any loss.</p>
<p>11.4 The Client, on the other hand, does not have the right to terminate the Agreement, unless the
Client can demonstrate that earlier fulfilment is essential to their business operations. In that case,
termination must take place in writing and within 5 (five) days at the latest after expiry of the period of
one (1) month.</p>
<p><strong>12. Guarantee</strong></p>
<p>12.1 The Contracted Party guarantees that the Services that it performs or outsources to third parties
and Software developed by it are performed to a high standard.</p>
<p>12.2 The Contracted Party does not guarantee that the Services and Software are accessible at all times
and without any interruptions or failures. The Contracted Party is not liable or liable for compensation in
any manner whatsoever towards the Client for any damage or loss that arises from or is the result of the
permanent or temporary unavailability or (premature) termination of the Services and/or breakdown of
the Software. The Contracted Party does not provide any guarantee with respect to the suitability of the
Services and/or Software for a specific purpose.</p>
<p>12.3 The Client acknowledges that the Services and/or Software are not completely free from
defects, errors and bugs as well as security problems. The Contracted Party also does not guarantee that
the Services and/or Software work properly in conjunction with all types or new versions of web
browsers and any other software and/or websites. Neither does the Contracted Party guarantee that the
Services and/or Software work properly in conjunction with all types of equipment.</p>
<p><strong>13. Liability</strong></p>
<p>13.1 The Contracted Party must make every effort to comply with the obligations under the
Agreement. Except in case of intent or gross negligence on the part of the Contracted Party or third
parties engaged by the Contracted Party in the execution of the Agreement, the Contracted Party shall
never be liable for any loss suffered by the Client.</p>
<p>13.2 As a further limitation of the Contracted Party’s liability towards the Client:
a) Only the damage for which the Contracted Party is insured is eligible for compensation
and only insofar as any compensation is paid to the Contracted Party by the Contracted Party’s
insurer.
b) The Contracted Party is never liable to compensate damage to a higher amount than that part
of the amount invoiced by the Contracted Party to the Client under the Agreement from which
the damage results, with a maximum of € 5.000,-.
c) The Contracted Party is not liable for damage, of any nature whatsoever, caused by the
Contracted Party’s reliance on incorrect and/or incomplete information provided by or on behalf
of the Client.
d) That the Contracted Party’s liability shall at all times be limited to direct damages
suffered/proven by the Client, and the Contracted party's liability shall never exceed the portion
of the invoice value of the relevant Agreement to which the liability relates, with a maximum of
€ 5.000,-. Any other form of damages is excluded from liability, including but not limited to
consequential damages, lost profits, lost business opportunities and the costs of mitigation,
prevention and assessment o</p>
<p>13.3 The Contracted Party shall never be liable to the Client for other use, or use by third parties, of
results of Services delivered or made available to the Client by Contracted Party.</p>
<p>13.4 Contracted Party’s liability for damage against which the Client is already insured is excluded at
all times</p>
<p>13.5 All the above limitations of liability may be invoked against the Client by Contracted Party's
employees or third parties involved by it in the performance of the Agreement.</p>
<p><strong>14. Indemnity</strong></p>
<p>The Client indemnifies the Contracted Party, its staff and any third parties engaged by the Contracted
Party against all claims from other third parties for payment of any (alleged) damage or loss suffered by
those third parties that was caused by the Contracted Party under the Agreement.</p>
<p><strong>15. Client’s obligations</strong></p>
<p>15.1 The Client is responsible for any structures and working methods prescribed by them or on their
behalf, as well as for any orders, directions and instructions given by them or on their behalf.
</p><p>
15.2 Without prejudice to the Client’s liability under the law, the Client is liable for all damage or loss
resulting from defects in items, building materials or resources that the Client has made available or has
prescribed, as well as for work performed or deliveries made by the Client or by third parties on the
Client’s instructions.
</p><p>
15.3 The consequences of compliance with statutory regulations or decisions by a public authority
that take effect after the day of the offer are the Client’s responsibility.</p>
<p><strong>16. Intellectual property rights</strong></p>
<p>16.1 In case of the provision of Services, and subject to the proviso that the Client has fulfilled all
obligations under the Agreement towards the Contracted Party, any intellectual property rights created
in the context of the Agreement (read: the source code), are transferred by the Contracted Party to the
Client, to the extent that they belong to the Contracted Party. Internal or external reusable parts
(‘packages’) developed by the Contracted Party and used and to be used by it, as well as parts that
qualify as open source material are exempted from this in the context of the provision of the Services.
The Contracted Party explicitly reserves the right to use and reuse these parts - to be designated and
qualified by the Contracted Party - in the performance of other agreements with third parties. The Client
acknowledges this authority, therefore also acknowledging that the Client does not have any property
rights or cannot allege to have any property rights in respect of these parts. To the extent necessary for
the use of the Services, the Contracted Party grants a non-exclusive, non-transferable,
non-sublicensable limited right under the terms of the Agreement to use these parts or the result of
these parts only in and for the benefit of their own company or organisation and only for the intended
use as agreed in the Agreement.</p><p>
16.2 The Contracted Party indemnifies the Client against damage or loss and costs resulting from a
third-party claim that the Software infringes intellectual property rights of those third parties. This
indemnity ceases to apply if the Client has adjusted or changed the Software in any way or uses or has
used the Software in a manner that does not correspond with the Software’s intended use.</p>
<p><strong>17. Use and licence</strong></p>
<p>17.1 The Client grants the Contracted Party a non-exclusive, transferable, sublicensable right to use
in the performance of the Agreement all content, data, information and details that the Client provides
to the Contracted Party. The Client guarantees that they are entitled to grant this licence. The Client
indemnifies the Contracted Party against all damage or loss and costs resulting from (alleged)
infringements of intellectual property rights or other rights of third parties as a result of the content,
data, information and details provided by the Client.</p>
<p>17.2 The Client is entitled to fit technical facilities to protect the Services (Software) in connection
with an agreed limitation of the content or term of the right to use these objects. The Client is not
allowed to remove or have such a technical facility removed or to circumvent or have it circumvented.
</p><p>
17.3 The Client is not allowed to remove or change any designation concerning the confidential
nature or concerning intellectual property rights from or in the Agreement, Software, programs,
hardware, documents and/or other materials.
</p><p>
17.4 Unless otherwise agreed in writing and save in exceptional cases provided by mandatory law,
the Client is not entitled to modify the Services or the result of the Services wholly or partially and/or to
have third parties perform repair work to such Services without the Contracted Party’s prior written
consent.</p>
<p><strong>18. Dispute resolution and applicable law</strong></p>
<p>18.1 All disputes that may arise between the Parties as a result of the Agreement or agreements
resulting from the Agreement are settled by the competent court in the district of Oost-Brabant.
</p><p>
18.2 The Agreement and the General Terms and Conditions are governed exclusively by Dutch law.
The Vienna Sales Convention 1980 does not apply.</p>
<h2>B. SPECIAL PART - SOFTWARE</h2>
<p><strong>19. Development</strong></p>
<p>19.1 The Contracted Party will develop the Software with due care according to the requirements and
specifications as agreed in the Agreement (hereinafter referred to as: ‘Specifications’). The development
work is always carried out by the Contracted Party on the basis of an obligation to use best endeavours.
</p><p>
19.2 The Client understands and acknowledges that the Specifications have been prepared on the
basis of the information provided by the Client. The Client guarantees that they have provided all
essential information for preparing the Specifications and the assignment to the best of their knowledge.
The Contracted Party is not liable for any damage or loss that is the result of or that has arisen from the
absence of any information necessary and/or required for preparing the Specifications.
</p><p>
19.3 When performing the development work, the Contracted Party is not obliged to follow the
instructions given by the Client. To the extent that the Contracted Party does follow those instructions, it
is entitled to charge a fee for this in accordance with its hourly rate applicable at the time. The Client
understands that implementing instructions may have consequences for the final result and the project’s
turnaround time.</p>
<p><strong>20. Maintenance</strong></p>
<p>The Contracted Party is only obliged to perform maintenance to the Software to the extent that such has
been agreed on in a separate Service Level Agreement (hereinafter referred to as ‘SLA’). The Contracted
Party may - but is never obliged to do so - correct errors in the Software at the Client’s request. The
Contracted Party may charge the costs for the repair work to the Client at the hourly rates applicable at
the time.</p>
<p><strong>21. Delivery and installation</strong></p>
<p>21.1 The Contracted Party will deliver the Software to the Client in the agreed manner. Dates and
times for completion or partial completion are indicative only and may never be regarded as strict
deadlines.</p>
<p>21.2 The Contracted Party will install the Software at the Client only if such has been agreed
between the Parties in the assignment. The Client will cooperate fully in the installation, including
granting the Contracted Party access to locations and equipment at all times when so requested.
</p><p>
21.3 The Client is responsible for the purchase and/or proper functioning of the infrastructure and
proper telecom facilities (including Internet connection) that are necessary for the performance of the
Agreement and the use of the Software. The Contracted Party is never responsible or liable for costs
arising from the use of telecommunications charged via the telecom provider.</p><p>
21.4 The Contracted Party is only obliged to provide the Client with a physical carrier with the
Software or source code of the Software if this has been agreed between the Parties.</p>
<p><strong>22. Acceptance</strong></p>
<p>22.1 After delivery and installation, if applicable, within the meaning of Article 21, the Client will test
the Software for errors within 1 (one) week. An error is understood to mean the substantial and
reproducible failure to meet the Specifications. During this testing period, the Client is not allowed to use
the Software for productive or operational purposes.
</p><p>
22.2 The Client will carry out the test referred to above in respect of the Software with sufficiently
qualified personnel and with sufficient scope and depth.
</p><p>
22.3 At the end of the above period, the Client will state to the Contracted Party in writing whether
the Client accepts the Software. The Software is also considered to have been accepted if the Client starts
using the Software after the above testing period has ended.
</p><p>
22.4 Acceptance of the Software may not be withheld on grounds not connected with the
Specifications, which are merely subjective and/or cosmetic in nature. In addition, acceptance may not be
withheld on account of the existence of minor errors that reasonably do not prevent the use of the
Software.</p><p>
22.5 To the extent that acceptance does not take place, the Client will inform the Contracted Party in
writing about any established errors in a clear-cut, understandable, complete and sufficiently concrete
manner within one week after the end of the testing period.</p><p>
22.6 The Contracted Party will make every effort to remedy the stated errors (including minor errors)
within a reasonable period, in which respect the Contracted Party is entitled to implement temporary
solutions, workarounds or problem-avoiding restrictions in the Software.</p><p>
22.7 A result of acceptance of the Software is that the Contracted Party has been discharged for the
fulfilment of its obligations regarding the development and installation – to the extent applicable – of the
Software, without prejudice to the Client’s rights agreed on where appropriate with respect to
maintenance, within the meaning of Article 20.</p>
      </Modal>
      <style>{`
        .terms {
    
          z-index: 10000;
        }
        .termsLink {
          color: white;
          text-decoration: none;
          transition: all 0.2s ease;
        }
        .termsLink:hover {
          background: #333;
          color: white;
        }
        .modal-overlayTERMS {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: fadeIn 0.3s ease;
          z-index: 10000;
        }
        .modal-contentTERMS {
          width: 100%;
          height: 100%;
          padding: 2rem;
          color: #333;
          position: relative;
          animation: scaleIn 0.3s ease;
          margin-top: 2rem;
          margin-bottom: 2rem;
          overflow-y: scroll;
        }
        .modal-closeTERMS {
          position: absolute;
          top: 1rem;
          right: 1rem;
          background: none;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          color: #333;
          padding: 0.5rem;
          line-height: 1;
          transition: transform 0.2s ease;
        }
        .modal-closeTERMS:hover {
          transform: rotate(90deg);
        }
       
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes scaleIn {
          from {
            transform: scale(0.95);
          }
          to {
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
}

export default Terms;
