import React, {useRef, useEffect, useState} from 'react';
import styles from './App.module.css';
import Content from './species/Content';
import Header from './species/Header';
import Contact from './organs/Contact';
import Socials from './organs/Socials';
import {  Element } from 'react-scroll';
import AnimatedText from './organs/SplashScreen';
import SplashScreen from './organs/SplashScreen';
import CookiesContent from './cells/CookiesContent';

function App() {

  const cursor = useRef<HTMLInputElement>(null);
  const footer = useRef<HTMLDivElement>(null);

  const [allowCookies, setAllowCookies] = useState<boolean>(false);

  const moveCursor = (e: any)  => {

    const curretCursor = cursor.current;

    var x = e.clientX;
    var y = e.clientY;
    curretCursor && (curretCursor.style.left = `${x}px`);
    curretCursor && (curretCursor.style.top = `${y}px`);
  }

  document.addEventListener('mousemove', moveCursor);
  useEffect(() => {
    if (footer && footer.current) {
      footer.current.addEventListener("mousemove", () =>  cursor.current && document.getElementById('cursor')?.classList.add('cursorHover'));
      footer.current.addEventListener("mouseout", () =>  cursor.current && document.getElementById('cursor')?.classList.remove('cursorHover'))
    }
  }, [footer, cursor]);

  return (
    <div className={styles.App}>
      <AnimatedText/>
      <Header/>
      <SplashScreen/>
      <Content cookies={allowCookies}/>
      <Element name="contact">
      <div id="footerId" className={styles.footer} ref={footer}>
          <Contact/>
      </div>
      </Element>
      <Socials/>
      <CookiesContent cookies={setAllowCookies}/>
      <div ref={cursor} id="cursor" className="cursor"></div>
    </div>
  );
}

export default App;
