import { Ellipsis, X } from "lucide-react";
import { useState } from "react";
import logo from '../assets/images/logo.webp';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";


const ResponsiveHeaderMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  

  const changeLanguage = () => {
    const activeLocale = i18n.resolvedLanguage; 

    activeLocale === "en" ? i18n.changeLanguage("nl") : i18n.changeLanguage("en");

  };

  return (
    <>
      <style>
        {`
          .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 1.5rem;
            z-index: 50;
            backdrop-filter: blur(10px);
          }
          
          .header-container {
            max-width: 1920px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          
          .logo {
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: -0.02em;
            position: absolute;
            left: 2%;
            transform: translateX(-2%);
            color: #000;
            margin: 0;
          }

          .logo-image {
          height: 3rem;
          }
          
          .menu-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            color: #000;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            margin-left: auto;
            transition: opacity 0.3s ease;
          }
          
          .menu-button:hover {
            opacity: 0.7;
          }
          
          .menu-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            z-index: 40;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease, visibility 0.5s ease;
          }
          
          .menu-overlay.open {
            opacity: 1;
            visibility: visible;
          }
          
          .menu-content {
            text-align: center;
          }
          
          .nav-link {
            display: block;
            font-size: 2.5rem;
            font-weight: 500;
            color: #000;
            text-decoration: none;
            padding: 0.5rem;
            margin: 1rem 0;
            transform: translateY(20px);
            opacity: 0;
            transition: transform 0.5s ease, opacity 0.5s ease;
          }
          
          .menu-overlay.open .nav-link {
            transform: translateY(0);
            opacity: 1;
          }
          
          .menu-overlay.open .nav-link:nth-child(1) { transition-delay: 0.1s; }
          .menu-overlay.open .nav-link:nth-child(2) { transition-delay: 0.2s; }
          .menu-overlay.open .nav-link:nth-child(3) { transition-delay: 0.3s; }
          .menu-overlay.open .nav-link:nth-child(4) { transition-delay: 0.4s; }
          .menu-overlay.open .nav-link:nth-child(5) { transition-delay: 0.5s; }
          
          .nav-link:hover {
            opacity: 0.7;
          }
          
          .menu-text {
            display: inline-block;
            margin-right: 0.5rem;
            font-size: 0.875rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
          }
          
          .icon {
            width: 24px;
            height: 24px;
            transition: transform 0.3s ease;
          }
          
          .menu-button:hover .icon {
            transform: scale(1.1);
          }
          
          @media (max-width: 640px) {
            .menu-text {
              display: none;
            }
            
            .nav-link {
              font-size: 2rem;
            }
            
            .header {
              padding: 1rem;
            }
            
            .logo {
              font-size: 1rem;
            }
          }
        `}
      </style>
      <header className="header">
        <div className="header-container">
          <h1 className="logo">
            <img src={logo} alt="archiCODE logo" className="logo-image"/>
          </h1>
          <button
            className="menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-expanded={isMenuOpen}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
           
            {isMenuOpen ? (
              <X className="icon" aria-hidden="true" />
            ) : (
              <Ellipsis className="icon" aria-hidden="true" />
            )}
          </button>
        </div>
      </header>

      <div className={`menu-overlay ${isMenuOpen ? "open" : ""}`}>
        <nav className="menu-content">
            
          <span className="nav-link" onClick={()=> setIsMenuOpen(false)}>
            <Link 
                onClick={()=> setIsMenuOpen(false)}
                activeClass="active" 
                to="services" 
                spy={true} 
                smooth={true} 
                offset={-100} 
                duration={500} 
                >
                {t("services")}
            </Link>
          </span>
          <span className="nav-link" onClick={()=> setIsMenuOpen(false)}>
            <Link 
                onClick={()=> setIsMenuOpen(false)}
                activeClass="active" 
                to="projects" 
                spy={true} 
                smooth={true} 
                offset={-100} 
                duration={500} 
                >
                {t("projects")}
            </Link>
          </span>
          <span className="nav-link" onClick={()=> setIsMenuOpen(false)}>
            <Link 
                onClick={()=> setIsMenuOpen(false)}
                activeClass="active" 
                to="process" 
                spy={true} 
                smooth={true} 
                offset={-100} 
                duration={500} 
                >
                {t("process")}
            </Link>
          </span>
          <span className="nav-link" onClick={()=> setIsMenuOpen(false)}>
            <Link 
                onClick={()=> setIsMenuOpen(false)}
                activeClass="active" 
                to="contact" 
                spy={true} 
                smooth={true} 
                offset={-100} 
                duration={500} 
                >
                {t("contact")}
            </Link>
          </span>
          <span className="nav-link" onClick={()=> setIsMenuOpen(false)}>
            <span 
                onClick={()=> changeLanguage()} 
                >
                {i18n.resolvedLanguage === "nl" ? "en": "nl"}
            </span>
          </span>
        </nav>
      </div>
    </>
  );
}

export default ResponsiveHeaderMenu;
