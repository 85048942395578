import styles from './Services.module.css';
import { useTranslation } from "react-i18next";

const Services = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div className={styles.services}>
            <h2 className={styles.title}>{t('our')} <strong>{t('servicesTitle')}</strong>.</h2>
            <div className={styles.wrapper}>
                <div className={styles.service}>
                    <h3 className={styles.sub}>Web Design & Development</h3>
                    <p className={styles.text}>{t("webService")}</p>
                </div>
                <div className={styles.service}>
                    <h3 className={styles.sub}>App Design & Development</h3>
                    <p className={styles.text}>{t('appService')}</p>
                </div>
                
            </div>
        </div>
    )
}

export default Services;