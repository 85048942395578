import loading from '../assets/images/loading.gif';

const Loading = () => {
    return (
        <div>
            <img src={loading} alt="loading"/>
        </div>
    )
}

export default Loading;