import WordCycler from '../cells/WordsCycler';
import ModalGreen from '../organs/ModalGreen';
import Process from '../organs/Process';
import Projects from '../organs/Projects';
import Services from '../organs/Services';
import styles from './Content.module.css';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useTranslation } from "react-i18next";
import WordCyclerNL from '../cells/WordsCyclerNL';


const Content = ({cookies}: {cookies: boolean}) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div>
            <div className={styles.container}>
            {i18n.resolvedLanguage === "nl" ? 
                <h1 className={styles.headline}> <WordCyclerNL/> websites <span className={styles.titleEnd}>die inspireren</span></h1>
                : 
                <h1 className={styles.headline}> <WordCycler/> websites <span className={styles.titleEnd}>tailored to inspire</span></h1>
            }
                
                <p className={styles.sub}>{t("headerSub")}</p>
                <ModalGreen cookies={cookies}/>
            </div>  
            <div className={styles.container}>
                <Element name="services">
                    <Services/>
                </Element>
            </div>
            <div className={styles.container}>
                <Element name="projects">
                    <Projects/>
                </Element>
            </div>
            <div className={styles.container}>
          
                <Element name="process">
                    <Process/>
                </Element>
  
            </div>
        </div>
        
    )
}

export default Content;