import styles from './Socials.module.css';

const Socials = () => {
    return (
        <div className={styles.wrapper}>
            <a href="https://www.instagram.com/archicode.nl" target="_blank" aria-label="visit our instagram profile">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.474" height="13.141" viewBox="299.5 953.424 13.474 13.141"><path fill="#787878" d="M310.383 953.424h-8.292c-1.439 0-2.591 1.122-2.591 2.526v8.088c0 1.403 1.15 2.526 2.591 2.526h8.292c1.438 0 2.591-1.123 2.591-2.526v-8.088c0-1.405-1.152-2.526-2.591-2.526zm.748 1.515h.288v2.245h-2.304v-2.245h2.016zm-6.794 3.707c.402-.563 1.15-.955 1.899-.955.748 0 1.497.393 1.899.955.288.394.461.844.461 1.347 0 1.292-1.094 2.305-2.36 2.305-1.266 0-2.361-1.013-2.361-2.305 0-.504.174-.954.462-1.347zm7.312 5.392c0 .675-.575 1.235-1.267 1.235h-8.292c-.69 0-1.268-.563-1.268-1.235v-5.393h2.017a3.532 3.532 0 0 0-.288 1.347c0 1.969 1.67 3.597 3.686 3.597s3.686-1.628 3.686-3.597a3.56 3.56 0 0 0-.287-1.347h2.015l-.002 5.393z"></path></svg>
            </a>
            <a href="https://www.linkedin.com/company/archicodenl" target="_blank" aria-label="visit our linkedin profile">
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#787878"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect x="0" fill="none" width="20" height="20"></rect> <g> <path d="M2.5 18h3V6.9h-3V18zM4 2c-1 0-1.8.8-1.8 1.8S3 5.6 4 5.6s1.8-.8 1.8-1.8S5 2 4 2zm6.6 6.6V6.9h-3V18h3v-5.7c0-3.2 4.1-3.4 4.1 0V18h3v-6.8c0-5.4-5.7-5.2-7.1-2.6z"></path> </g> </g></svg>
                 </a>
        </div>
    )
}

export default Socials;