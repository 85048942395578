import styles from './Projects.module.css';
import HorizontalProjects from './ProjectsHorizontal';
import { useTranslation } from "react-i18next";


const Projects = () => {
        const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('projectsTitle')}<strong>{t('projectsTitleSub')}</strong>.</h2>
                <HorizontalProjects/>
        </div>
    )
}

export default Projects;