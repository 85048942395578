import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ProcessPoint {
  title: string;
  description: string;
}
const ProgressExpand = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const processPoints: ProcessPoint[] = [
    {
      title: t('research'),
      description: t('researchDescription'),
    },
    {
      title: t('design'),
      description: t('designDescription'),
    },
    {
      title: t('development'),
      description: t('developmentDescription'),
    },
    {
      title: t('qa'),
      description: t('qaDescription'),
    },
    {
      title: t('support'),
      description: t('supportDescription'),
    },
  ];
  return (
    <div className="process-container">
      <style>{`
        .process-container {
          max-width: 800px;
          margin: 40px auto;
          padding: 20px;
          font-family: system-ui, -apple-system, sans-serif;
        }
        .process-point {
          border-radius: 12px;
          padding: 24px;
          margin-bottom: 24px;
          transition: all 0.3s ease;
          border: 1px solid #eaeaea;
          position: relative;
        }
        .process-number {
          position: absolute;
          left: -12px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          background: #f0f0f0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          color: #666;
          transition: all 0.3s ease;
        }
        .process-point.expanded .process-number {
          background:#98B08B;
          color: white;
        }
        .process-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
        .process-title {
          font-size: 18px;
          font-weight: 500;
          color: #333;
          margin: 0;
          margin-left: 20px;
          transition: color 0.3s ease;
        }
        .process-point.expanded .process-title {
          color: #98B08B;
        }
        .expand-button {
          width: 28px;
          height: 28px;
          position: relative;
          background: none;
          border: 2px solid #eaeaea;
          border-radius: 50%;
          padding: 0;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .expand-button:hover {
          border-color: #98B08B;
        }
        .expand-button::before,
        .expand-button::after {
          content: '';
          position: absolute;
          background: #666;
          transition: all 0.3s ease;
        }
        .expand-button::before {
          width: 12px;
          height: 2px;
          top: 11px;
          left: 6px;
        }
        .expand-button::after {
          width: 2px;
          height: 12px;
          top: 6px;
          left: 11px;
        }
        .expand-button.expanded {
          border-color:#98B08B;
          transform: rotate(180deg);
        }
        .expand-button.expanded::before,
        .expand-button.expanded::after {
          background:#98B08B;
          width: 12px;
          height: 2px;
          top: 11px;
          left: 6px;
        }
        .expand-button.expanded::before {
          transform: rotate(45deg);
        }
        .expand-button.expanded::after {
          transform: rotate(-45deg);
          left: 6px;
          top: 11px;
        }
        .process-content {
          height: 0;
          overflow: hidden;
          transition: all 0.3s ease;
          opacity: 0;
        }
        .process-content.expanded {
          height: auto;
          margin-top: 16px;
          opacity: 1;
        }
        .description {
          color: #666;
          line-height: 1.6;
          font-size: 15px;
          transform: translateY(20px);
          opacity: 0;
          transition: all 0.3s ease;
          margin-left: 20px;
        }
        .description.visible {
          transform: translateY(0);
          opacity: 1;
        }
        .process-point:hover {
          border-color:#98B08B;
        }
        .process-point.expanded {
          border-color:#98B08B;
          background: rgba(152, 176, 139, 0.05);
        }
      `}</style>
      {processPoints.map((point, index) => (
        <div
          key={index}
          className={`process-point ${expandedIndex === index ? "expanded" : ""}`}
        >
          <div
            className="process-header"
            onClick={() =>
              setExpandedIndex(expandedIndex === index ? null : index)
            }
          >
            <span className="process-number">{index + 1}</span>
            <h3 className="process-title">{point.title}</h3>
            <button
              className={`expand-button ${expandedIndex === index ? "expanded" : ""}`}
              aria-label={expandedIndex === index ? "Collapse" : "Expand"}
            />
          </div>
          <div
            className={`process-content ${expandedIndex === index ? "expanded" : ""}`}
          >
            <p
              className={`description ${expandedIndex === index ? "visible" : ""}`}
            >
              {point.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
export default ProgressExpand;
