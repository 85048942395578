import styles from './Contact.module.css';
import { Linkedin, Instagram } from "lucide-react";
import { useTranslation } from "react-i18next";
import Terms from '../cells/Terms';
import Cookies from '../cells/Cookies';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>

            
            <div className={styles.top}>
                <div className={styles.social}>
                    <a className={styles.link} href="https://www.linkedin.com/company/archicodenl" target="_blank" aria-label="visit our linkedin profile" rel="noreferrer"><Linkedin strokeWidth="1" className="icon" aria-hidden="true" /></a>
                    <a className={styles.link} href="https://www.instagram.com/archicode.nl" target="_blank" aria-label="visit our instagram profile" rel="noreferrer"><Instagram strokeWidth="1"  className="icon" aria-hidden="true" /></a>
                </div>
            
                <p>info@archicode.nl</p>
            </div>
            <div className={styles.center}>
                <h2>{t('start')}</h2>
            </div>
            <div className={styles.bottom}>
                <div>
                    
                    {/* <p>KvK: 123456789</p> */}
                    <p>'s-Hertogenbosch</p>
                    <Terms/>
                    <Cookies/>
                </div>
                <div>
                    <p>©archiCODE 2025</p>
                    <p>All rights reserved</p>
                    
                </div>
            </div>
        </div>
        </div>
    )
}

export default Contact;