import React, { useEffect, useState } from "react";
import SequentialLetters from "../cells/LettersSplash";

interface SplashScreenState {
  showContent: boolean;
  showFirstText: boolean;
  showSecondText: boolean;
}
const SplashScreen = (): JSX.Element => {
  const [state, setState] = useState<SplashScreenState>({
    showContent: false,
    showFirstText: false,
    showSecondText: false,
  });
  useEffect(() => {
    const timer3 = setTimeout(
      () =>
        setState((prev) => ({
          ...prev,
          showContent: true,
        })),
      1700,
    );
    return () => {
      clearTimeout(timer3);
    };
  }, []);
  return (
    <>
      <style>
        {`
          .splash-screen {
            position: fixed;
            inset: 0;
            background-color: black;
            z-index: 9999;
            height: 100vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translateY(0);
            transition: transform 0.5s ease;
          }

          .splash-screen.hide {
            transform: translateY(-100%);
          }

        `}
      </style>

      <div
        className={`splash-screen ${state.showContent ? "hide" : ""}`}
        role="presentation"
      >
        <SequentialLetters/>
      </div>
    </>
  );
}
export default SplashScreen;
