import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import translationDutch from './translations/dutch/translations.json';
import translationEnglish from './translations/english/translations.json';

i18n
  .use(initReactI18next)
  .init({                 
    lng: "nl",
    fallbackLng: "nl",
    debug: true,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translation: translationEnglish,
      },
      nl: {
        translation: translationDutch,
      },
    },
  });

export default i18n;