import React, { useEffect, useState } from "react";

function StyleTag() {
  return (
    <style>
      {`
        @keyframes slideUp {
          0% {
            transform: translateY(50px);
            opacity: 0;
          }
          15% {
            transform: translateY(0);
            opacity: 1;
          }
          85% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(-50px);
            opacity: 0;
          }
        }
        
        .animated-word {
          animation: slideUp 2500ms cubic-bezier(0.4, 0, 0.2, 1);
        }
          .container {
    height: 3.4rem;
    display: inline-block;
   width: 300px;
    overflow: hidden;
    position: relative;
  }
    @media screen and (max-width:548px) {
    .word {
    right: auto;
    left:0;
    }
    }
      @media screen and (min-width:549px) and (max-width: 1000px){
    .word {
    right: 0;
    }
    }
  .word {
    font-weight: 600;
    font-size: 3rem;
    position: absolute;
    color: #1a1a1a;
    z-index: 1;
    right: 0.5rem;
  },
      `}
    </style>
  );
}
const WordCyclerNL = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const words = ["moderne", "duurzame"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <StyleTag />
      <div className="container">
        <div
          className="word animated-word"
          key={currentIndex}
          role="text"
          aria-label={`Animated word: ${words[currentIndex]}`}
        >
          {words[currentIndex]}
        </div>
      </div>
    </>
  );
}
export default WordCyclerNL;
