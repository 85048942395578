import styles from './Process.module.css';
import ProgressExpand from './ProgressHorizontal';
import { useTranslation } from "react-i18next";

const Process = () => {
        const { t } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('our')} <strong>{t('process')}</strong>.</h2>
            <ProgressExpand/>
        </div>
    )
}

export default Process;