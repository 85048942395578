import { ArrowRight } from "lucide-react";
import valderstraat2 from '../assets/images/valderstraat2.webp';
import lichtstad2 from '../assets/images/lichtstad2.webp';
import lenabe2 from '../assets/images/lenabe2.webp';
import { useTranslation } from "react-i18next";

const HorizontalProjects = () => {
   const { t } = useTranslation();
  return (
    <div className="containerHOR">
      <div className="scroll-indicatorHOR">
        
        <ArrowRight className="arrowHOR" />
      </div>
      <div className="scroll-containerHOR">
        <div className="card-wrapperHOR">

          <a className="cardHOR"  href="https://www.tandartspraktijkvalderstraat.nl" target="_blank" rel="noreferrer">
            <div
              className="card-backgroundHOR"
              style={{
                backgroundImage: `url(${valderstraat2})`,
              }}
            >
              <div className="gradient-overlayHOR"></div>
            </div>
            <div className="card-contentHOR">
              <h2>Valderstraat</h2>
              <p>{t('dentalWebsite')}</p>
              <span className="view-projectHOR">{t('viewProject')}</span>
            </div>
          </a>

          

          <a className="cardHOR" href="https://www.tandartspraktijklichtstad.nl" target="_blank" rel="noreferrer">
            <div
              className="card-backgroundHOR"
              style={{
                backgroundImage: `url(${lichtstad2})`,
              }}
            >
              <div className="gradient-overlayHOR"></div>
            </div>
            <div className="card-contentHOR">
              <h2>Lichtstad</h2>
              <p>{t('dentalWebsite')}</p>
              <span className="view-projectHOR">{t('viewProject')}</span>
            </div>
          </a>

          <div className="cardHOR">
            <div
              className="card-backgroundHOR"
              style={{
                backgroundImage: `url(${lenabe2})`,
              }}
            >
              <div className="gradient-overlayHOR"></div>
            </div>
            <div className="card-contentHOR">
              <h2>LenaBe</h2>
              <p>{t('hairProject')}</p>
              <span className="view-projectHOR">{t('viewProject')}</span>
            </div>
          </div>

        </div>
      </div>
      <style>{`
        .containerHOR {
        margin-top: 2rem;
          width: 100vw;
          
        }
        .scroll-indicatorHOR {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 8px;
          padding: 16px 24px;
        }
        .scroll-indicatorHOR span {
          font-size: 14px;
          color: #666;
        }
        .arrowHOR {
          width: 16px;
          height: 16px;
          color: #666;
          animation: pulse 2s infinite;
        }
        @keyframes pulse {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 1; }
        }
        .scroll-containerHOR {
          width: 100%;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .scroll-containerHOR::-webkit-scrollbar {
          display: none;
        }
        .card-wrapperHOR {
          display: flex;
        }
        .cardHOR {
        text-decoration: none;
          position: relative;
          width: 85vw;
          height: 70vh;
          flex-shrink: 0;
          margin: 0 16px;
          scroll-snap-align: center;
        }
        .card-backgroundHOR {
          position: absolute;
          inset: 0;
          background-size: cover;
          background-position: center;
          border-radius: 12px;
        }
        .gradient-overlayHOR {
          position: absolute;
          inset: 0;
          background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
          border-radius: 12px;
        }
        .card-contentHOR {
          position: relative;
       
          padding: 32px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
        .card-contentHOR h2 {
          color: white;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .card-contentHOR p {
          color: rgba(255, 255, 255, 0.9);
          font-size: 14px;
          margin-bottom: 16px;
        }
        .view-projectHOR {
          color: white;
          opacity: 0;
          transform: translateY(8px);
          transition: all 0.3s ease;
        }
        .cardHOR:hover .view-projectHOR {
          opacity: 1;
          transform: translateY(0);
        }
        @media (min-width: 768px) {
          .cardHOR {
            width: 600px;
          }
        }
        @media (min-width: 1024px) {
          .scroll-indicatorHOR {
            display: none;
          }
        }
      `}</style>
    </div>
  );
}

export default HorizontalProjects;