
import React from "react";
const SequentialLetters = () => {
  const letters = "archiCODE";
  return (
    <div
      className="letters-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        position: "relative",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      {letters.split("").map((letter, index) => (
        <span
          key={index}
          style={{
            position: "absolute",
            fontSize: "100px",
            color: "white",
            fontWeight: "bold",
            opacity: 0,
            animation: `fadeInOut 1.8s steps(1) ${index * 0.2}s infinite`,
          }}
        >
          {letter}
        </span>
      ))}
      <style>
        {`
          @keyframes fadeInOut {
            0%, 10% {
              opacity: 1;
            }
            11%, 100% {
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};
export default SequentialLetters;
