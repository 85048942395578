import React, { useEffect, useState } from "react";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
function Modal({ isOpen, onClose, children }: ModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <div className="modal-overlayTERMS" onClick={onClose}>
      <div className="modal-contentTERMS" onClick={(e) => e.stopPropagation()}>
        <button className="modal-closeTERMS" onClick={onClose}>
          ×
        </button>
        {children}
      </div>
    </div>
  );
}
const Cookies = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="terms">
      <a className="termsLink"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
      >
        Cookies
      </a>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Cookies</h2>
        <p>archiCODE uses cookies for functional and analytical purposes. Cookies are small, locally stored, files that websites use to track information about users. For more information about cookies, please visit: https://cookiesandyou.com.</p>
        <p>The main purpose of cookies placed by our website is to track user experience throughout the website. We save data about how users navigate the website, but are strict to never collect personally identifiable data. This means we don’t collect data like exact locations, ip-addresses and keystrokes. We collect the minimum amount of data required for our purposes and will never sell any of it to third parties. We do not use cookies to retarget, remarket or identify users in any way.</p>
        <p>To effectively and safely process data collected we work with two data-processors (Google, recaptcha). Google and recaptcha are restricted from processing data without explicit request and prohibited from becoming a controller and processing and sharing the data any further.</p>
        <p></p>
      </Modal>
      <style>{`
        .terms {
          z-index: 10000;
        }
        .termsLink {
          color: white;
          text-decoration: none;
          transition: all 0.2s ease;
        }
        .termsLink:hover {
          background: #333;
          color: white;
        }
        .modal-overlayTERMS {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: fadeIn 0.3s ease;
          z-index: 10000;
        }
        .modal-contentTERMS {
          width: 100%;
          height: 100%;
          padding: 2rem;
          color: #333;
          position: relative;
          animation: scaleIn 0.3s ease;
          margin-top: 3rem;
          margin-bottom: 2rem;
          overflow-y: scroll;
        }
        .modal-closeTERMS {
          position: absolute;
          top: 2rem;
          right: 1rem;
          background: none;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          color: #333;
          padding: 0.5rem;
          line-height: 1;
          transition: transform 0.2s ease;
        }
        .modal-closeTERMS:hover {
          transform: rotate(90deg);
        }
       
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes scaleIn {
          from {
            transform: scale(0.95);
          }
          to {
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
}

export default Cookies;
