import React, { useState, useRef } from "react";
import { X, ArrowLeft, ArrowRight } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import Loading from "../cells/Loading";
import { useTranslation } from "react-i18next";

interface FormData {
  options: string[];
  additionalInfo: string;
  name: string;
  email: string;
  phone: string;
  company: string;
}
interface FormErrors {
  options?: string;
  additionalInfo?: string;
  name?: string;
  email?: string;
  phone?: string;
  company?: string;
}
interface FormModalProps {
  onClose: () => void;
  cookies: boolean;
}
function FormModal({ onClose, cookies }: FormModalProps) {
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const { t } = useTranslation();

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    options: [],
    additionalInfo: "",
    name: "",
    email: "",
    phone: "",
    company: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [emailError, setEmailError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const options = [
    "Web design",
    "Web development",
    "App design",
    "App development",
  ];
  const validateStep1 = () => {
    const newErrors: FormErrors = {};
    if (formData.options.length === 0) {
      newErrors.options = t('selectOne');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateStep2 = () => {
    const newErrors: FormErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = t('requiredName');
    }
    if (!formData.email.trim()) {
      newErrors.email = t('requiredEmail');
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('validEmail');
    }
    if (!formData.phone.trim()) {
      newErrors.phone = t('requiredPhone');
    }
    if (!formData.company.trim()) {
      newErrors.company = t('requiredCompany');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const form = useRef<HTMLFormElement>(null);

  const handleNext = () => {
    if (step === 1 && validateStep1()) {
      setStep(2);
    } else if (step === 2 && validateStep2()) {
        cookies && recaptchaRef.current && recaptchaRef.current.execute();
        setLoading(true);

      const formCurrent = form.current;

      emailjs.sendForm("service_ctgyh86", "template_94phpup", formCurrent!, "y4T6LxoRtRIcZ_mtQ")
      .then((result) => {
        setLoading(false);
        setDone(true);
        setEmailError(false);
      }, (error) => {
        setLoading(false);
        setDone(false);
        setEmailError(true);
      });
    }
  };
  const handleBack = () => {
    setStep(1);
    setErrors({});
    setEmailError(false);
  };
  const handleCheckboxChange = (option: string) => {
    setFormData((prev) => ({
      ...prev,
      options: prev.options.includes(option)
        ? prev.options.filter((item) => item !== option)
        : [...prev.options, option],
    }));
  };
  return (
    <>
      <div className="modal-overlay" />
      <div className="modal">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(step / 2) * 100}%`,
            }}
          />
        </div>
        <div className="modal-header">
          <h2 className="modal-title">
            <strong>{t("hello")}</strong>
            {t('details')}
          </h2>
          <button onClick={onClose} className="close-button">
            <X />
          </button>
        </div>
        { !loading && !done &&
        <form className="modal-content"  ref={form}>
          {step === 1 ? (
            <div className="form-section">
              <label className="form-label">
                {t('interested')}
              </label>
              <div className="checkbox-group">
                {options.map((option) => (
                  <label key={option} className="checkbox-label">
                    <div className="checkbox-wrapper">
                      <input
                        name={option}
                        type="checkbox"
                        className="checkbox"
                        checked={formData.options.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <div className="checkbox-custom"></div>
                    </div>
                    <span>{option}</span>
                  </label>
                ))}
              </div>
              {errors.options && <p className="error-text">{errors.options}</p>}
              <div className="float-label-field">
                <textarea
                  id="additionalInfo"
                  name="other"
                  value={formData.additionalInfo}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      additionalInfo: e.target.value,
                    }))
                  }
                  rows={4}
                  placeholder={t('otherDetails')}
                />
                <label htmlFor="additionalInfo">
                  {t('other')}
                </label>
              </div>
            </div>
          ) : (
            <div className="form-section">
                <label className="form-label">
                {t('more')}
              </label>
              {[
                {
                  label: `${t('name')}`,
                  key: "name",
                  placeholder: "John Doe",
                },
                {
                  label: `${t('email')}`,
                  key: "email",
                  type: "email",
                  placeholder: "john@example.com",
                },
                {
                  label: `${t('phone')}`,
                  key: "phone",
                  type: "tel",
                  placeholder: "+1 234 567 890",
                },
                {
                  label: `${t('company')}`,
                  key: "company",
                  placeholder: "Company Inc.",
                },
              ].map((field) => (
                <div key={field.key} className="float-label-field">
                  <input
                    id={field.key}
                    name={field.key}
                    type={field.type || "text"}
                    placeholder={field.placeholder}
                    value={formData[field.key as keyof FormData]}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [field.key]: e.target.value,
                      }))
                    }
                  />
                  <label htmlFor={field.key}>{field.label}</label>
                  {errors[field.key as keyof FormErrors] && (
                    <p className="error-text">
                      {errors[field.key as keyof FormErrors]}
                    </p>
                  )}
                </div>
              ))}
              {formData.options.map(option => <input key={option} type="hidden" name={option} value={option}/>)}
            {formData.additionalInfo && <input key="other" type="hidden" name="other" value={formData.additionalInfo}/>}
            </div>
          )
          }
        </form>}
        {loading && !done && <div className="feedback"><Loading/></div>}
        {done && !loading && <div className="feedback">
          
            <p>
              {t('feedbackPositive')}
              <br/><br/>
            <span className="feedbackSub">{t('getInTouch')}</span>
            </p>
            
            </div>}
        {emailError && <div className="feedback">{t('oops')}</div>}
        {!done && <div className="modal-footer">
          <div className="footer-content">
            {step === 2 ? <button
              onClick={handleBack}
              className="nav-button"
            >
              <ArrowLeft className="arrow-left" />
            </button> : <div></div>}
            {step === 1 ? (
              <button onClick={handleNext} className="nav-button">
                <ArrowRight className="arrow-right" />
              </button>
            ) : (
              <button onClick={handleNext} className="nav-button primary">
                {t('send')}
              </button>
            )}
          </div>
        </div>}
        
      </div>
      
      {cookies && <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfxhpsqAAAAAGQVvn71ggBIgemVsH7wIpoTFgjk"
   />}
    </>
  );
}
const ModalGreen = ({cookies}: {cookies: boolean}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { i18n } = useTranslation();
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <style>
        {`
        .feedback {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
            flex-direction: column;
            padding: 1rem;
        }
            .feedbackSub {
            font-size: 0.75rem;
        
            }
          .cta {
            background-color: var(--color-black);
            padding: var(--padding-l);
            color: var(--color-white);
            border-radius: var(--border-radius-l);
            border: 1px solid var(--color-off-white);
            margin-top: var(--margin-xl);
            width: 16rem;
            transition: background-color 0.2s ease;
          }
            .ctaEN::after {
            content: "Aming for market leadership?"
}
            .ctaEN:hover::after {
                content: "Let us help you!"
            }
                .ctaNL::after {
            content: "Streeft u naar marktleiderschap?"
}
            .ctaNL:hover::after {
                content: "Wij helpen u graag!"
            }

            .cta:focus {
                outline: var(--color-off-black) 2px solid;
            }

            .cta:hover {
                background-color: var(--color-off-white);
                border: 1px solid var(--color-black);
                color: var(--color-black);
                transition: all .3s;
            }
       
          .modal {
            position: fixed;
            inset: 0;
            background-color: white;
            z-index: 50;
            overflow-y: auto;
            animation: modalIn 0.4s cubic-bezier(0.16, 1, 0.3, 1);
          }
          .modal.closing {
            animation: modalOut 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          }
          @keyframes modalIn {
            0% {
              opacity: 0;
              transform: translateY(10px) scale(0.98);
            }
            100% {
              opacity: 1;
              transform: translateY(0) scale(1);
            }
          }
          @keyframes modalOut {
            0% {
              opacity: 1;
              transform: translateY(0) scale(1);
            }
            100% {
              opacity: 0;
              transform: translateY(10px) scale(0.98);
            }
          }
          .modal-overlay {
            position: fixed;
            inset: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 49;
            animation: overlayIn 0.3s ease-out;
          }
          .modal-overlay.closing {
            animation: overlayOut 0.2s ease-in;
          }
          @keyframes overlayIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes overlayOut {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
          .progress-bar {
            width: 100%;
            height: 4px;
            background-color: #f9fafb;
          }
          .progress-bar-fill {
            height: 100%;
            background-color: #98B08B;
            transition: width 0.5s ease-in-out;
          }
          .modal-header {
            max-width: 672px;
            margin: 0 auto;
            padding: 3rem 1.5rem 2rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          }
          .modal-title {
            font-size: 2rem;
            font-weight: 300;
            line-height: 1.2;
            color: #1f2937;
          }
          .close-button {
            padding: 0.5rem;
            border: none;
            background: none;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s ease;
            transform-origin: center;
          }
          .close-button:hover {
            background-color: #f3f4f6;
            transform: rotate(90deg);
          }
          .close-button svg {
            width: 1.25rem;
            height: 1.25rem;
            color: #9ca3af;
          }
          .modal-content {
            max-width: 672px;
            margin: 0 auto;
            padding: 0 1.5rem 8rem;
          }
          .form-section {
            margin-bottom: 2.5rem;
          }
          .form-label {
            font-size: 0.875rem;
            font-weight: 500;
            color: #374151;
            margin-bottom: 2rem;
            display: block;
          }
          .checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
          .checkbox-label {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            cursor: pointer;
            color: #4b5563;
            margin-bottom: 1.75em;
            transition: color 0.2s ease;
          }
          .checkbox-label:hover {
            color: #1f2937;
          }
          .checkbox-wrapper {
            position: relative;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
          }
          .checkbox {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
          }
          .checkbox-custom {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-color: white;
            border: 2px solid #e5e7eb;
            border-radius: 4px;
            transition: all 0.2s ease;
          }
          .checkbox:focus + .checkbox-custom {
            box-shadow: 0 0 0 2px rgba(152, 176, 139, 0.2);
          }
          .checkbox-label:hover .checkbox-custom {
            border-color: var(--color-black);
          }
          .checkbox:checked + .checkbox-custom {
            background-color:var(--color-black);
            border-color:var(--color-black);
          }
          .checkbox-custom::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 5px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scale(0);
            opacity: 0;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          }
          .checkbox:checked + .checkbox-custom::after {
            transform: rotate(45deg) scale(1);
            opacity: 1;
          }
          .float-label-field {
            position: relative;
            margin-bottom: 1.5rem;
          }
            .float-label-field textarea {
                margin-top: 1rem;
            }
                @media screen and (max-width: 900px) {
                .float-label-field input,
                .float-label-field textarea {
                width: 80vw !important;
                }
                }
          .float-label-field input,
          .float-label-field textarea {
            width: 100%;
            padding: 1rem;
            border: 1px solid #e5e7eb;
            border-radius: 0.5rem;
            font-size: 1rem;
            background: transparent;
            transition: all 0.2s ease;
            outline: none;
          }
          .float-label-field input::placeholder,
          .float-label-field textarea::placeholder {
            color: transparent;
          }
          .float-label-field input:focus::placeholder,
          .float-label-field textarea:focus::placeholder {
            color: #9ca3af;
            transition: color 0.2s ease 0.1s;
          }
          .float-label-field input:focus,
          .float-label-field textarea:focus {
            border-color: #98B08B;
            box-shadow: 0 0 0 1px #98B08B;
          }
          .float-label-field label {
            position: absolute;
            left: 1rem;
            top: 1rem;
            font-size: 1rem;
            color: #6b7280;
            pointer-events: none;
            transition: all 0.2s ease;
            background: white;
            padding: 0 0.25rem;
          }
          .float-label-field input:focus + label,
          .float-label-field textarea:focus + label,
          .float-label-field input:not(:placeholder-shown) + label,
          .float-label-field textarea:not(:placeholder-shown) + label {
            top: -0.5rem;
            left: 0.75rem;
            font-size: 0.875rem;
            color: #98B08B;
          }
          .error-text {
            color: #f87171;
            font-size: 0.875rem;
            margin-top: 0.5rem;
          }
          .modal-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1.5rem;
            background-color: white;
            border-top: 1px solid #f3f4f6;
          }
          .footer-content {
            max-width: 672px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
          }
          .nav-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            border: none;
            border-radius: 0.5rem;
            background: none;
            color: #4b5563;
            cursor: pointer;
            transition: all 0.2s ease;
          }
          .nav-button svg {
            transition: transform 0.2s ease;
          }
          .nav-button:hover svg.arrow-left {
            transform: translateX(-4px);
          }
          .nav-button:hover svg.arrow-right {
            transform: translateX(4px);
          }
          .nav-button.primary {
            background-color: var(--color-black);
            color: white;
            padding: 0.75rem 2rem;
            font-size: 0.875rem;
            font-weight: 500;
            width: auto;
          }
          .nav-button.primary:hover {
            background-color: #839b77;
          }
          @media (max-width: 640px) {
            .modal-header {
              padding: 2rem 1rem 1.5rem;
            }
            .modal-title {
              font-size: 1.5rem;
            }
            .modal-content {
              padding: 0 1rem 8rem;
            }
            .modal-footer {
              padding: 1rem;
            }
          }
        `}
      </style>
      <div className="app">
        <button className={`cta ${i18n.resolvedLanguage === "nl" ? "ctaNL": "ctaEN"}`} onClick={() => setIsModalOpen(true)}></button>
        {isModalOpen && <FormModal onClose={handleClose} cookies={cookies}/>}
      </div>
    </>
  );
}
export default ModalGreen;
